import OSS from 'ali-oss'

const client = new OSS({
    region: 'oss-cn-shanghai',
    // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
    accessKeyId: 'LTAI4GKZKSMibSm3w7X3tvza',
    accessKeySecret: 'li8Rv6sprQy1gFRz469m2ZcPEAZb80',
    // 填写Bucket名称。
    bucket: 'dingchw-oss'
});

export const LOAD_IMAGES = ()=>{
    try{
        return client.get('wedding/1.jpg')
    }catch(e){
        console.log(e)
    }
}