<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <img :src="imageUrl"/>
  <button @click="test">click me</button>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import {LOAD_IMAGES} from './utils/oss_client.js'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data: function(){
    return {
      imageUrl: '',
    }
  },
  methods:{
    test(){
        LOAD_IMAGES().then(result=>{
          console.log(result.content);
          let blob = new Blob([result.content],{type: 'image/jpeg'});
          let url = window.URL.createObjectURL(blob);
          console.log(url);
          this.imageUrl = url;
        })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
